import _findIndex from "lodash/findIndex";

import {stickyAtcSetZoorixMPromoBadge} from "./sticky-atc/stickyAtc";

export const tabs = [
    {
        id: 'status',
        content: 'Dashboard',
    },
    {
        id: 'products',
        content: 'Offers',
    },
    {
        id: 'settings',
        content: 'Settings',
    },
    {
        id: 'texts',
        content: 'Texts',
    },
    {
        id: 'warranty-moderation',
        content: 'Warranty Mgmt',
    },
    {
        id: 'analytics',
        content: 'ROI',
    },
    {
        id: 'support',
        content: 'Support',
    },
    {
        id: 'manual-installation',
        content: "Installation",
    },
    {
        id: 'faq',
        content: "Tutorials & FAQ",
    },
    {
        id: 'css-editor',
        content: 'Code Editor',
    },
    {
        id: 'integrations',
        content: 'Integrations',
    },
    {
        id: 'plans',
        content: 'Plans',
    },

];

export const integrationTabs = [
    {
        id: 'all',
        content: 'All',
    },
    {
        id: 'page-builders',
        content: 'Page Builders',
    },
];

export const recommendedAppsTabs = [
    {
        id: 'all',
        content: 'All',
    },
    {
        id: 'page-builders',
        content: 'Page Builders',
    },
    /*{
        id: 'translations',
        content: 'Translations',
    },*/
    {
        id: 'videos',
        content: 'Videos',
    },
    {
        id: 'dropshipping',
        content: 'Dropshipping',
    },
    {
        id: 'inventory',
        content: 'Inventory',
    },
    {
        id: 'seo',
        content: 'SEO',
    },
    {
        id: 'billing',
        content: 'Billing',
    },
];

export const recommendedApps = [
    {
        title: 'Tolstoy',
        description: 'Join 5,000 modern brands driving conversions with Video Commerce',
        badge: 'Videos',
        badge_class: 'videos-app',
        link: 'https://www.gotolstoy.com/ecommerce?utm_medium=app&utm_campaign=partner&utm_source=zoorix',
        integration: false,
        recommended_app: true,
        page_builder: false,
        translation: false,
        video: true,
    },
    {
        title: 'PageFly',
        description: 'Easily drag & drop Zoorix deals within PageFly’s page builder',
        badge: 'Page Builder',
        badge_class: 'page-builder-app',
        link: 'https://pagefly.io?ref=MkukKDYa&target=app-listing',
        integration: true,
        recommended_app: true,
        page_builder: true,
    },
    {
        title: 'AutoDS',
        description: 'Quickly find and import trending products, monitor pricing & stock 24/7',
        badge: 'Dropshipping',
        badge_class: 'dropshipping-app',
        link: 'https://apps.shopify.com/autods?utm_medium=app&utm_campaign=partner&utm_source=zoorix',
        integration: false,
        recommended_app: true,
        page_builder: false,
        translation: false,
        video: false,
        dropshipping: true,
    },
    {
        title: 'GemPages',
        description: 'Create pages and build up your stores fast to boost sales',
        badge: 'Page Builder',
        badge_class: 'page-builder-app',
        link: 'https://apps.shopify.com/gempages?utm_source=zoorix&utm_medium=referral&utm_campaign=refer-partner',
        integration: true,
        recommended_app: false,
        page_builder: true,
    },
    {
        title: 'Beae',
        description: 'Effortlessly build high-conversion Shopify pages',
        badge: 'Page Builder',
        badge_class: 'page-builder-app',
        link: 'https://apps.shopify.com/beae?utm_source=Partner&utm_medium=Zoorix&utm_campaign=placementinappp',
        /* icon: 'https://public.zoorix.com/partners/beae-icon.webp', */
        integration: true,
        recommended_app: true,
        page_builder: true,
    },
    {
        title: 'Fabrikatör',
        description: 'Forecast, plan and execute your inventory operations',
        badge: 'Inventory',
        badge_class: 'inventory-app',
        link: 'https://apps.shopify.com/fabrikator?utm_source=zoorix&utm_medium=zoorix_refs&utm_campaign=zoorix_referral&utm_id=zoorix_referrals',
        integration: false,
        recommended_app: true,
        page_builder: false,
        inventory: true
    },
    {
        title: 'Schema Plus for SEO',
        description: "Boost Your Shopify Store's SEO With The Power of Schema",
        badge: 'SEO',
        badge_class: 'seo-app',
        link: 'https://apps.shopify.com/schema-plus?utm_source=zoorix&utm_medium=partnership&utm_campaign=schemaplus',
        integration: false,
        recommended_app: true,
        page_builder: false,
        inventory: false,
        seo: true,
    },
    {
        title: 'Fordeer: Invoice Order Printer',
        description: "Increase repurchase rate and customers' loyalty with personalized PDF invoices",
        badge: 'Billing',
        badge_class: 'billing-app',
        link: 'https://link.fordeer.io/HN5uHP',
        integration: false,
        recommended_app: true,
        page_builder: false,
        inventory: false,
        seo: false,
        billing: true
    },
    {
        title: 'LangShop',
        description: 'Translate Zoorix widgets and offers using LangShop',
        badge: 'Translation',
        badge_class: 'translation-app',
        link: 'https://apps.shopify.com/langshop?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app',
        /* icon: 'https://public.zoorix.com/images/lang-icon.svg',*/
        integration: false,
        recommended_app: false,
        page_builder: false,
        translation: true,
    },
    {
        title: 'Langify',
        description: 'Translate Zoorix widgets and offers using Langify',
        badge: 'Translation',
        badge_class: 'translation-app',
        link: 'https://apps.shopify.com/langify?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app',
        integration: false,
        recommended_app: false,
        page_builder: false,
        translation: true,
    },
    {
        title: 'Transcy',
        description: 'Translate Zoorix widgets and offers using Transcy',
        badge: 'Translation',
        badge_class: 'translation-app',
        link: 'https://apps.shopify.com/transcy-multiple-languages?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app',
        integration: false,
        recommended_app: false,
        page_builder: false,
        translation: true,
    },
    {
        title: 'WeGlot',
        description: 'Translate Zoorix widgets and offers using WeGlot',
        badge: 'Translation',
        badge_class: 'translation-app',
        link: 'https://apps.shopify.com/weglot?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app',
        integration: false,
        recommended_app: false,
        page_builder: false,
        translation: true,
    },
    {
        title: 'G | Translate',
        description: 'Translate Zoorix widgets and offers using G | Translate',
        badge: 'Translation',
        badge_class: 'translation-app',
        link: 'https://apps.shopify.com/multilingual-shop-by-gtranslate?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app',
        integration: false,
        recommended_app: false,
        page_builder: false,
        translation: true,
    },
    {
        title: 'Translation Lab',
        description: 'Translate Zoorix widgets and offers using Translation Lab',
        badge: 'Translation',
        badge_class: 'translation-app',
        link: 'https://apps.shopify.com/content-translation?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app',
        integration: false,
        recommended_app: false,
        page_builder: false,
        translation: true,
    },
];


export const HANDLE_TAB_CHANGE = 'HANDLE_TAB_CHANGE';
export const CHANGE_INTEGRATIONS_TAB = 'CHANGE_INTEGRATIONS_TAB';
export const CHANGE_RECOMMENDED_APPS_TAB = 'CHANGE_RECOMMENDED_APPS_TAB';
export const ACTIVATE_GENERIC_TOAST = 'ACTIVATE_GENERIC_TOAST';
export const INIT_NAV = 'INIT_NAV';
export const SLIDER_CLICKED = 'SLIDER_CLICKED';
export const BUNDLE_CLICKED = 'BUNDLE_CLICKED';
export const WARRANTY_CLICKED = 'WARRANTY_CLICKED';
export const CART_DRAWER_CLICKED = 'CART_DRAWER_CLICKED';
export const VOLUME_DISCOUNT_CLICKED = 'VOLUME_DISCOUNT_CLICKED';
export const MIX_AND_MATCH_CLICKED = 'MIX_AND_MATCH_CLICKED';
export const BOGO_CLICKED = 'BOGO_CLICKED';
export const DISMISS_GENERIC_TOAST = 'DISMISS_GENERIC_TOAST';
export const APP_CLICKED = 'APP_CLICKED';

export const handleTabChange = tab => ({
    type: HANDLE_TAB_CHANGE,
    tab,
});

export const changeIntegrationsTab = tab => ({
    type: CHANGE_INTEGRATIONS_TAB,
    tab,
});

export const changeRecommendedAppsTab = tab => ({
    type: CHANGE_RECOMMENDED_APPS_TAB,
    tab,
});

export const activateGenericToast = content => ({
    type: ACTIVATE_GENERIC_TOAST,
    content,
});

export const initNav = (json, appType) => ({
    type: INIT_NAV,
    installation: json.installation,
    packageName: json.package_name,
    appType,
});

export const sliderClicked = () => ({
    type: SLIDER_CLICKED,
});

export const bundleClicked = () => ({
    type: BUNDLE_CLICKED,
});

export const warrantyClicked = () => ({
    type: WARRANTY_CLICKED,
});

export const appClicked = () => ({
    type: APP_CLICKED,
});

export const cartDrawerClicked = () => ({
    type: CART_DRAWER_CLICKED,
});

export const volumeDiscountClicked = () => ({
    type: VOLUME_DISCOUNT_CLICKED,
});

export const mixAndMatchClicked = () => ({
    type: MIX_AND_MATCH_CLICKED,
});

export const bogoClicked = () => ({
    type: BOGO_CLICKED,
});

export const dismissGenericToast = () => ({
    type: DISMISS_GENERIC_TOAST,
});

export function changeTab(name) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(handleTabChange(getTabIndexByName(name, state.nav.tabs)));
    }
}

export function upgradeToZoorixM() {
    return (dispatch, getState) => {
        const state = getState();
        window.open(state.stickyAtc.storeData.upgrade_url, '_blank');
        dispatch(stickyAtcSetZoorixMPromoBadge(false));
    }
}

export function getTabIndexByName(name, tabs) {
    return _findIndex(tabs, { id: name });
}
