import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const INIT_WARRANTY_SETTINGS = 'INIT_WARRANTY_SETTINGS';
export const ACTIVATE_TOAST = 'ACTIVATE_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const RESTORE_WARRANTY_TO_DEFAULTS = 'RESTORE_WARRANTY_TO_DEFAULTS';
export const SAVE_WARRANTY_SETTINGS_PROCESSING = 'SAVE_WARRANTY_SETTINGS_PROCESSING';
export const SAVE_WARRANTY_SETTINGS_ERROR = 'SAVE_WARRANTY_SETTINGS_ERROR';
export const SAVE_WARRANTY_SETTINGS_SUCCESS = 'SAVE_WARRANTY_SETTINGS_SUCCESS';
export const UPLOAD_WARRANTY_DEFAULT = 'UPLOAD_WARRANTY_DEFAULT';
export const CANCEL_UPLOAD_WARRANTY_DEFAULT = 'CANCEL_UPLOAD_WARRANTY_DEFAULT';
export const UPLOAD_WARRANTY_DEFAULT_SUCCESS = 'UPLOAD_WARRANTY_DEFAULT_SUCCESS';
export const UPLOAD_WARRANTY_DEFAULT_FAILURE = 'UPLOAD_WARRANTY_DEFAULT_FAILURE';
export const RESTORE_ZOORIX_WARRANTY_DEFAULT = 'RESTORE_ZOORIX_WARRANTY_DEFAULT';
export const SEND_TEST_EMAIL_PROCESSING = 'SEND_TEST_EMAIL_PROCESSING';
export const SEND_TEST_EMAIL_SUCCESS = 'SEND_TEST_EMAIL_SUCCESS';
export const SEND_TEST_EMAIL_ERROR = 'SEND_TEST_EMAIL_ERROR';
export const SEND_TEST_SMS_PROCESSING = 'SEND_TEST_SMS_PROCESSING';
export const SEND_TEST_SMS_SUCCESS = 'SEND_TEST_SMS_SUCCESS';
export const SEND_TEST_SMS_ERROR = 'SEND_TEST_SMS_ERROR';
export const ON_TEST_EMAIL_OR_PHONE_CHANGE = 'ON_TEST_EMAIL_OR_PHONE_CHANGE';
export const NOTIFICATION_EMAIL_CHANGE = 'NOTIFICATION_EMAIL_CHANGE';
export const ON_DISCOUNT_PERCENTAGE_CHANGE = 'ON_DISCOUNT_PERCENTAGE_CHANGE';
export const EMAIL_SUBJECT_CHANGE = 'EMAIL_SUBJECT_CHANGE';
export const EMAIL_TEMPLATE_CHANGE = 'EMAIL_TEMPLATE_CHANGE';
export const SMS_SUBJECT_CHANGE = 'SMS_SUBJECT_CHANGE';
export const SMS_TEMPLATE_CHANGE = 'SMS_TEMPLATE_CHANGE';
export const WARRANTY_STORE_NAME_CHANGE = 'WARRANTY_STORE_NAME_CHANGE';
export const WARRANTY_SHOW_ON_CART_CHANGE = 'WARRANTY_SHOW_ON_CART_CHANGE';
export const SEND_NOTIFICATION_CHANGE = 'SEND_NOTIFICATION_CHANGE';
export const WARRANTY_IN_CARTS_CHANGE = 'WARRANTY_IN_CARTS_CHANGE';
export const SEND_PURCHASE_NOTIFICATION_CHANGE = 'SEND_PURCHASE_NOTIFICATION_CHANGE';
export const SEND_ACCEPT_NOTIFICATION_CHANGE = 'SEND_ACCEPT_NOTIFICATION_CHANGE';
export const SEND_REJECT_NOTIFICATION_CHANGE = 'SEND_REJECT_NOTIFICATION_CHANGE';

export const initWarrantySettings = json => ({
    type: INIT_WARRANTY_SETTINGS,
    settings: json.warranty_settings,
    tokenId: json.token_id,
    planName: json.plan_name,
});

export const activateToast = () => ({
    type: ACTIVATE_TOAST,
});

export const saveWarrantySettingsSuccess = () => ({
    type: SAVE_WARRANTY_SETTINGS_SUCCESS,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const restoreWarrantyToDefaults = () => ({
    type: RESTORE_WARRANTY_TO_DEFAULTS,
});

export const saveWarrantySettingsProcessing = () => ({
    type: SAVE_WARRANTY_SETTINGS_PROCESSING,
});

export const saveWarrantySettingsError = () => ({
    type: SAVE_WARRANTY_SETTINGS_ERROR,
});

export const uploadWarrantyDefault = () => ({
    type: UPLOAD_WARRANTY_DEFAULT,
});

export const cancelUploadWarrantyDefault = () => ({
    type: CANCEL_UPLOAD_WARRANTY_DEFAULT,
});

export const uploadWarrantyDefaultSuccess = () => ({
    type: UPLOAD_WARRANTY_DEFAULT_SUCCESS,
});

export const uploadWarrantyDefaultFailure = () => ({
    type: UPLOAD_WARRANTY_DEFAULT_FAILURE,
})

export const restoreZoorixWarrantyDefault = () => ({
    type: RESTORE_ZOORIX_WARRANTY_DEFAULT,
})

export const sendTestEmailProcessing = email_type => ({
    type: SEND_TEST_EMAIL_PROCESSING,
    email_type,
})

export const sendTestEmailSuccess = email_type => ({
    type: SEND_TEST_EMAIL_SUCCESS,
    email_type,
})

export const sendTestEmailError = email_type => ({
    type: SEND_TEST_EMAIL_ERROR,
    email_type,
})

export const sendTestSmsProcessing = email_type => ({
    type: SEND_TEST_SMS_PROCESSING,
    email_type,
})

export const sendTestSmsSuccess = email_type => ({
    type: SEND_TEST_SMS_SUCCESS,
    email_type,
})

export const sendTestSmsError = email_type => ({
    type: SEND_TEST_SMS_ERROR,
    email_type,
})

export const onTestEmailOrPhoneChange = (email_type, text) => ({
    type: ON_TEST_EMAIL_OR_PHONE_CHANGE,
    text,
    email_type,
})

export const notificationEmailChange = text => ({
    type: NOTIFICATION_EMAIL_CHANGE,
    text,
})

export const onDiscountPercentageChange = text => ({
    type: ON_DISCOUNT_PERCENTAGE_CHANGE,
    text,
})

export const emailSubjectChange = (email_type, text) => ({
    type: EMAIL_SUBJECT_CHANGE,
    text,
    email_type,
})

export const emailTemplateChange = (email_type, text) => ({
    type: EMAIL_TEMPLATE_CHANGE,
    text,
    email_type,
})

export const smsSubjectChange = (email_type, text) => ({
    type: SMS_SUBJECT_CHANGE,
    text,
    email_type,
})

export const smsTemplateChange = (email_type, text) => ({
    type: SMS_TEMPLATE_CHANGE,
    text,
    email_type,
})

export const warrantyStoreNameChange = text => ({
    type: WARRANTY_STORE_NAME_CHANGE,
    text,
})

export const warrantyShowOnCartChange = text => ({
    type: WARRANTY_SHOW_ON_CART_CHANGE,
    text,
})

export const sendNotificationChange = bool => ({
    type: SEND_NOTIFICATION_CHANGE,
    bool,
})

export const warrantyInCartsChange = bool => ({
    type: WARRANTY_IN_CARTS_CHANGE,
    bool,
})

export const sendPurchaseNotificationChange = bool => ({
    type: SEND_PURCHASE_NOTIFICATION_CHANGE,
    bool,
})

export const sendAcceptNotificationChange = bool => ({
    type: SEND_ACCEPT_NOTIFICATION_CHANGE,
    bool,
})

export const sendRejectNotificationChange = bool => ({
    type: SEND_REJECT_NOTIFICATION_CHANGE,
    bool,
})

export function saveWarrantySettings() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveWarrantySettingsProcessing());
        return fetch(`https://${host}/warranty/save_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    settings: state.settingsWarranty.settings,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(saveWarrantySettingsSuccess());
                    dispatch(activateToast());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(saveWarrantySettingsError());
                }
            });
    }
}

export function sendTestEmail(email_type) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(sendTestEmailProcessing(email_type));
        return fetch(`https://${host}/warranty/send_test_email`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    email: state.settingsWarranty.emailOrPhone[email_type],
                    subject: state.settingsWarranty.settings[`${email_type}_email_subject`],
                    template: state.settingsWarranty.settings[`${email_type}_email_template`],
                    email_type: email_type,
                    store_name: state.settingsWarranty.settings.store_name,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(sendTestEmailSuccess(email_type));
                } else {
                    dispatch(sendTestEmailError(email_type));
                }
            });
    }
}

export function sendTestSMS(email_type) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(sendTestSmsProcessing(email_type));
        return fetch(`https://${host}/warranty/send_test_sms`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    phone: state.settingsWarranty.emailOrPhone[email_type],
                    sender_id: state.settingsWarranty.settings[`${email_type}_sms_subject`],
                    template: state.settingsWarranty.settings[`${email_type}_sms_template`],
                    email_type: email_type,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(sendTestSmsSuccess(email_type));
                } else {
                    dispatch(sendTestSmsError(email_type));
                }
            });
    }
}


