import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _assign from "lodash/assign";
import _each from "lodash/each";
import _keys from "lodash/keys";

import {
    ACTIVATE_TOAST,
    APP_SETTINGS_INIT,
    APP_SETTINGS_RESTORE_TO_DEFAULTS,
    APP_SETTINGS_SAVE_SETTINGS_ERROR,
    APP_SETTINGS_SAVE_SETTINGS_INIT, APP_SETTINGS_SAVE_SETTINGS_SUCCESS,
    DISMISS_TOAST, GENERAL_SETTINGS_CHANGE_BOOL, PRICE_ORDER_COMPARE_TO_LEFT_CHANGE,
    SELECT_LOCATION,
    SET_LOCATIONS,
    SHOW_ONLY_FROM_LOCATIONS,
    SHOW_OUT_OF_STOCK, WIDGET_ORDER_CHANGE,
} from "../actions/settingsApp";
import {
    ABOVE_CART_BOTTOM_CHANGE,
    ABOVE_CART_TOP_CHANGE,
    ABOVE_PRODUCT_BOTTOM_CHANGE,
    ABOVE_PRODUCT_MAIN_CHANGE, APP_BLOCKS_CHANGE, CART_BOTTOM_SELECT_LAST_CHANGE,
    CART_BOTTOM_SELECTOR_CHANGE, CART_TOP_SELECT_LAST_CHANGE,
    CART_TOP_SELECTOR_CHANGE, CART_TOTAL_SELECTOR_CHANGE,
    CLOSE_SLIDER_INVENTORY_MODAL,
    FACEBOOK_PIXEL_CHANGE,
    IMAGE_SHARPNESS_CHANGE, KLAVIYO_CHANGE,
    PRODUCT_BOTTOM_SELECTOR_CHANGE,
    PRODUCT_MAIN_SELECTOR_CHANGE, ROUND_UP_CHANGE,
    ORDER_COMBINE_CHANGE,PRODUCT_COMBINE_CHANGE,SHIPPING_COMBINE_CHANGE,
} from "../actions/settings";

const widgetPositions = ['slider_position', 'bundles_position', 'volume_position', 'warranty_position'];

function calcIsLocationSelected(locations) {
    const result = {};
    _each(locations, location => {
        result[location.id] = {
            selected: location.include,
        }
    });
    return result;
}

function arrangeWidgetOrder(newState) {
    newState.widgetOrder = {};
    if (widgetPositions.some(key => newState.generalSetting.values[key] !== 0 && !newState.generalSetting.values[key])) {
        // some of them are null or undefined
        widgetPositions.forEach((key, idx) => newState.generalSetting.values[key] = idx);
    }
    _forEach(widgetPositions, key => newState.widgetOrder[newState.generalSetting.values[key]] = key);
}

const settingsApp = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case APP_SETTINGS_INIT:
            newState = {};
            newState.showOutOfStock = action.upsell.values.showOutOfStock;
            newState.showOnlyFromLocations = action.upsell.values.showOnlyFromLocations;
            newState.defaults = {
                showOutOfStock: action.upsell.defaults.showOutOfStock,
                showOnlyFromLocations: action.upsell.defaults.showOnlyFromLocations,
            };
            newState.hasReadInventory = action.hasReadInventory;
            newState.fetchingLocations = true;
            newState.planName = action.planName;
            newState.jsSelectors = action.jsSelectors;
            newState.generalSetting = _cloneDeep(action.generalSetting);
            arrangeWidgetOrder(newState);
            break;
        case SHOW_OUT_OF_STOCK:
            newState = _cloneDeep(state);
            newState.showOutOfStock = action.bool;
            if (!action.bool) {
                newState.inventoryModalOpen = true;
            }
            break;
        case GENERAL_SETTINGS_CHANGE_BOOL:
            newState = _cloneDeep(state);
            newState.generalSetting.values[action.attr] = action.bool === 'yes';
            break;
        case PRICE_ORDER_COMPARE_TO_LEFT_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.price_order_compare_to_left = action.bool;
            break;
        case SHOW_ONLY_FROM_LOCATIONS:
            newState = _cloneDeep(state);
            newState.showOnlyFromLocations = action.bool;
            if (action.bool) {
                newState.inventoryModalOpen = true;
            }
            break;
        case SELECT_LOCATION:
            newState = _cloneDeep(state);
            newState.isLocationSelected[action.id] = { selected: action.bool };
            break;
        case WIDGET_ORDER_CHANGE:
            newState = _cloneDeep(state);
            const targetId = _keys(newState.widgetOrder).find(k => newState.widgetOrder[k] === action.text);
            const temp = newState.widgetOrder[action.id];
            newState.widgetOrder[action.id] = newState.widgetOrder[targetId];
            newState.widgetOrder[targetId] = temp;
            break;
        case ACTIVATE_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case SET_LOCATIONS:
            newState = _cloneDeep(state);
            newState.fetchingLocations = false;
            newState.locations = action.locations;
            newState.isLocationSelected = calcIsLocationSelected(action.locations);
            break;
        case APP_SETTINGS_RESTORE_TO_DEFAULTS:
            newState = _cloneDeep(state);
            _assign(newState, _cloneDeep(state.defaults));
            newState.generalSetting.values = _cloneDeep(newState.generalSetting.defaults);
            arrangeWidgetOrder(newState);
            break;
        case APP_SETTINGS_SAVE_SETTINGS_INIT:
            newState = _cloneDeep(state);
            newState.isSubmitting = true;
            break;
        case APP_SETTINGS_SAVE_SETTINGS_ERROR:
            newState = _cloneDeep(state);
            newState.isSubmitting = false;
            newState.submissionError = true;
            break;
        case APP_SETTINGS_SAVE_SETTINGS_SUCCESS:
            newState = _cloneDeep(state);
            newState.isSubmitting = false;
            newState.submissionError = false;
            widgetPositions.forEach(key => newState.generalSetting.values[key] = action.json.setting[key]);
            arrangeWidgetOrder(newState);
            break;
        case CLOSE_SLIDER_INVENTORY_MODAL:
            newState = _cloneDeep(state);
            newState.inventoryModalOpen = false;
            break;
        case ABOVE_PRODUCT_MAIN_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.above_product_main = action.text === 'above';
            break;
        case ABOVE_PRODUCT_BOTTOM_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.above_product_bottom = action.text === 'above';
            break;
        case ABOVE_CART_TOP_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.above_cart_top = action.text === 'above';
            break;
        case ABOVE_CART_BOTTOM_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.above_cart_bottom = action.text === 'above';
            break;
        case PRODUCT_MAIN_SELECTOR_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.product_main_selector = action.text;
            break;
        case PRODUCT_BOTTOM_SELECTOR_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.product_bottom_selector = action.text;
            break;
        case CART_TOP_SELECTOR_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.cart_top_selector = action.text;
            break;
        case CART_BOTTOM_SELECTOR_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.cart_bottom_selector = action.text;
            break;
        case CART_TOTAL_SELECTOR_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.cart_total_selector = action.text;
            break;
        case IMAGE_SHARPNESS_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.image_sharpness = action.text;
            break;
        case FACEBOOK_PIXEL_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.facebook_pixel = action.text;
            break;
        case KLAVIYO_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.enable_klaviyo = action.text === 'enabled';
            break;
        case CART_TOP_SELECT_LAST_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.cart_top_selector_take_last = action.text === 'last';
            break;
        case CART_BOTTOM_SELECT_LAST_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.cart_bottom_selector_take_last = action.text === 'last';
            break;
        case ROUND_UP_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.round_up_prices = action.text === 'round';
            break;
        case APP_BLOCKS_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.enable_app_blocks = action.text === 'enabled';
            break;
        case ORDER_COMBINE_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.combines_with_order_discounts = action.text === 'enabled';
            break;
        case PRODUCT_COMBINE_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.combines_with_product_discounts = action.text === 'enabled';
            break;
        case SHIPPING_COMBINE_CHANGE:
            newState = _cloneDeep(state);
            newState.generalSetting.values.combines_with_shipping_discounts = action.text === 'enabled';
            break;    
        default:
            return state;
    }
    return newState;
};

export default settingsApp;
