import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";
import _find from "lodash/find";

import {
    BOGO_ADD_PRODUCT,
    BOGO_CHANGE_OFFER_STRING, BOGO_CHANGE_PRODUCT_INIT_QTY,
    BOGO_CONFIRM_PRODUCT_GROUP,
    BOGO_CREATE_OFFER,
    BOGO_DELETE_OFFER,
    BOGO_DISCOUNT_AMOUNT_CHANGE,
    BOGO_DISCOUNT_TYPE_CHANGE,
    BOGO_DISMISS_TOAST,
    BOGO_LOCALE_CHANGE, BOGO_LOCK_MAX_QTY_CHANGE, BOGO_MAX_QTY_CHANGE,
    BOGO_MIN_QTY_CHANGE,
    BOGO_OPEN_MODAL, BOGO_OPEN_SHORT_CODE_MODAL,
    BOGO_PUSH_SOURCE_PRODUCT_GROUP,
    BOGO_REMOVE_PRODUCT, BOGO_SAVING_SETTINGS,
    BOGO_SEARCHING_PRODUCT,
    BOGO_SET_DISPLAY,
    BOGO_SET_OFFER_TO_DELETE,
    BOGO_SET_PRODUCT_GROUP_TYPE,
    BOGO_SET_SEARCH_PRODUCT_TITLE,
    BOGO_SET_SEARCHED_PRODUCTS, BOGO_SET_SELECTED_PRODUCTS, BOGO_SETTING_COLOR_CHANGE, BOGO_SETTINGS_TEXT_CHANGE,
    BOGO_TEXT_CHANGE,
    BOGO_TOAST,
    BOGO_TOGGLE_BOOL,
    BOGO_TOGGLING_BOOL, BOGO_TRIGGER_LOCATION_CHANGE, BOGO_VALIDATE_OFFER,
    CREATING_BOGO,
    EDIT_BOGO,
    INIT_BOGO, INIT_BOGO_SETTINGS,
    LOADING_BOGOS,
    POPULATE_BOGO_OFFERS,
    SAVING_BOGO
} from "../actions/bogo";
import {calcLocales} from "./texts";
import {fillDefault} from "./mixAndMatch";


function setIntersection(setA, setB) {
    return new Set([...setA].filter(x => setB.has(x)));
}

const bogo = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_BOGO:
            newState = _cloneDeep(state);
            newState.offers = _cloneDeep(action.json.offers);
            newState.toggling = {};
            newState.openModals = {};
            break;
        case INIT_BOGO_SETTINGS:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.json.bogo_settings);
            Object.keys(newState.settings.defaults).forEach(attr => fillDefault(newState, attr));
            break;
        case BOGO_OPEN_SHORT_CODE_MODAL:
            newState = _cloneDeep(state);
            newState.shortCodeModalOpen = action.bool;
            newState.shortCodeOfferId = action.id;
            newState.shortCode = `<span zoorix-widget="bogo" zoorix-offer-id="${action.id}" zoorix-location="custom"></span>`;
            break;
        case POPULATE_BOGO_OFFERS:
            newState = _cloneDeep(state);
            newState.offers = _cloneDeep(action.json.offers);
            break;
        case BOGO_SET_SEARCHED_PRODUCTS:
            newState = _cloneDeep(state);
            newState.searchedProducts = _cloneDeep(action.json.result.data.data.products.edges);
            break;
        case BOGO_SET_SELECTED_PRODUCTS:
            newState = _cloneDeep(state);
            if (newState.productGroupType === 'source') {
                newState.selectedProducts = _cloneDeep(newState.offer.offer.source_groups[newState.productGroupIdx].products);
            } else if (newState.productGroupType === 'target') {
                newState.selectedProducts = _cloneDeep(newState.offer.offer.target_groups[newState.productGroupIdx].products);
            }
            break;
        case BOGO_ADD_PRODUCT:
            newState = _cloneDeep(state);
            newState.selectedProducts.push({
                id: action.node.id,
                title: action.node.title,
                handle: action.node.handle,
                imgSrc: action.node.images?.edges[0]?.node?.originalSrc,
                init_qty: newState.productGroupType === 'source' ? 1 : 0,
            });
            break;
        case BOGO_REMOVE_PRODUCT:
            newState = _cloneDeep(state);
            _remove(newState.selectedProducts, {id: action.id});
            break;
        case BOGO_CHANGE_PRODUCT_INIT_QTY:
            newState = _cloneDeep(state);
            {
                const product = _find(newState.selectedProducts, {id: action.id});
                product.init_qty = parseInt(action.text);
            }
            break;
        case BOGO_CREATE_OFFER:
            newState = _cloneDeep(state);
            if (action.json.available_locales) newState.availableLocales = _cloneDeep(action.json.available_locales);
            newState.offer = {
                enabled: true,
                name: action.json.name,
                texts: _cloneDeep(action.json.translations),
                offer: {
                    discount: {
                        type: 'percentage',
                        amount: 0,
                    },
                    source_groups: [{
                        group_type: 'products',
                        min_qty: 1,
                        products: [],
                    }],
                    target_groups: [{
                        group_type: 'products',
                        max_qty: 0,
                        lock_max_qty: true,
                        products: [],
                    }],
                },
                navigate_to: 'checkout',
                triggers: {
                    on_offer_items: true,
                    product_groups: [],
                },
                trigger_locations: {
                    product_main: true,
                    product_bottom: false,
                    cart_top: false,
                    cart_bottom: false,
                }
            }
            newState.displayState = 'edit_offer';
            calcLocales(newState, newState.offer.texts);
            break;
        case LOADING_BOGOS:
            newState = _cloneDeep(state);
            newState.loadingBogos = action.bool;
            break;
        case CREATING_BOGO:
            newState = _cloneDeep(state);
            newState.creatingBogo = action.bool;
            break;
        case SAVING_BOGO:
            newState = _cloneDeep(state);
            newState.savingBogo = action.bool;
            break;
        case BOGO_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case BOGO_VALIDATE_OFFER:
            newState = _cloneDeep(state);
            {
                const sourceProductIds = new Set(newState.offer.offer.source_groups.map(sg => sg.products.map(p => p.id)).flatten());
                const targetProductIds = new Set(newState.offer.offer.target_groups.map(tg => tg.products.map(p => p.id)).flatten());
                newState.offer.invalid = setIntersection(sourceProductIds, targetProductIds).size > 0;
            }
            break;
        case BOGO_CONFIRM_PRODUCT_GROUP:
            newState = _cloneDeep(state);
            if (newState.productGroupType === 'source') {
                newState.offer.offer.source_groups[newState.productGroupIdx].products = _cloneDeep(newState.selectedProducts);
            } else if (newState.productGroupType === 'target') {
                const targetGroup = newState.offer.offer.target_groups[newState.productGroupIdx];
                targetGroup.products = _cloneDeep(newState.selectedProducts);
                if (targetGroup.lock_max_qty) {
                    targetGroup.max_qty = targetGroup.products.length;
                }
            }
            break;
        case BOGO_PUSH_SOURCE_PRODUCT_GROUP:
            newState = _cloneDeep(state);
            newState.offer.offer.source_groups.push(_cloneDeep(action.productGroup));
            break;
        case EDIT_BOGO:
            newState = _cloneDeep(state);
            newState.offer = _cloneDeep(newState.offers.find(o => o.id === action.id));
            newState.displayState = 'edit_offer';
            calcLocales(newState, newState.offer.texts);
            break;
        case BOGO_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            newState.toastContent = action.text;
            break;
        case BOGO_SET_DISPLAY:
            newState = _cloneDeep(state);
            newState.displayState = action.text;
            break;
        case BOGO_DISCOUNT_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.type = action.text;
            break;
        case BOGO_DISCOUNT_AMOUNT_CHANGE:
            newState = _cloneDeep(state);
            if (action.text < 0) action.text = 0;
            newState.offer.offer.discount.amount = parseFloat(action.text);
            break;
        case BOGO_SET_SEARCH_PRODUCT_TITLE:
            newState = _cloneDeep(state);
            newState.searchedProductTitle = action.text;
            break;
        case BOGO_MIN_QTY_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.source_groups[action.idx].min_qty = parseFloat(action.text);
            break;
        case BOGO_LOCK_MAX_QTY_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.target_groups[action.idx].lock_max_qty = action.text === 'yes';
            break;
        case BOGO_MAX_QTY_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.target_groups[action.idx].max_qty = parseInt(action.text);
            break;
        case BOGO_SET_PRODUCT_GROUP_TYPE:
            newState = _cloneDeep(state);
            newState.productGroupType = action.text;
            newState.productGroupIdx = action.idx;
            break;
        case BOGO_LOCALE_CHANGE:
            newState = _cloneDeep(state);
            newState.locale = action.locale;
            break;
        case BOGO_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.texts[newState.locale][action.attr] = action.text;
            break;
        case BOGO_SET_OFFER_TO_DELETE:
            newState = _cloneDeep(state);
            newState.offerToDelete = action.id;
            break;
        case BOGO_SEARCHING_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchedProductSpinner = action.bool;
            break;
        case BOGO_SAVING_SETTINGS:
            newState = _cloneDeep(state);
            newState.savingSettings = action.bool;
            break;
        case BOGO_SETTING_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.values[action.attr] = action.hex;
            break;
        case BOGO_DELETE_OFFER:
            newState = _cloneDeep(state);
            _remove(newState.offers, {id: action.id});
            break;
        case BOGO_OPEN_MODAL:
            newState = _cloneDeep(state);
            newState.openModals[action.modalName] = action.bool;
            break;
        case BOGO_CHANGE_OFFER_STRING:
            newState = _cloneDeep(state);
            newState.offer[action.attr] = action.text;
            break;
        case BOGO_SETTINGS_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.values[action.attr] = action.text;
            break;
        case BOGO_TRIGGER_LOCATION_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.trigger_locations[action.attr] = action.bool;
            break;
        case BOGO_TOGGLE_BOOL:
            newState = _cloneDeep(state);
            {
                const offer = newState.offers.find(o => o.id === action.id);
                offer[action.attr] = !offer[action.attr];
            }
            break;
        case BOGO_TOGGLING_BOOL:
            newState = _cloneDeep(state);
            newState.toggling[action.attr] = newState.toggling[action.attr] || {};
            newState.toggling[action.attr][action.id] = action.bool;
            break;
        default:
            return state;
    }
    return newState;
};

export default bogo;
